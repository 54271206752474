import React, { useState } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import '../../../styles/global.css'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { createLinks as CreateLinks } from '../../../graphql/mutations'


const AddLinks = ({ type, userId, handleRefresh }) => {
    const [visibleBox, setVisible] = useState(false);
    const [linkName, setLinkName] = useState('')
    const [link, setLink] = useState('')

    //random linkId
    const linkId = Math.random().toString(10) + 'Abc#';

    const handleSubmit = () => {
        handleCreateLink()
        setLinkName('')
        setLink('')
        setVisible(!visibleBox);
        handleRefresh()
    }

    const handleClick = () => {
        setVisible(!visibleBox);
    }

    const handleCreateLink = async () => {
        try {
            const linkCreated = await API.graphql(graphqlOperation(CreateLinks,
                { input: { linkId: linkId, link_name: linkName, userLinksId: userId, link: link, link_type: type } }))
            console.log(linkCreated.data)
        }
        catch (err) {
            console.log(err)
        }
    }


    return (

        <div>
            {visibleBox &&
                <div className='top-0 left-0 fixed bg-gray-800/50 min-h-screen w-full flex justify-center items-center'>
                    <form onSubmit={handleSubmit}>
                        <div className='bg-white rounded-sm px-4 '>
                            <div className='flex'>
                                <div className="poopins text-xl font-bold w-full  text-center pt-4 mb-6">{type}</div>
                                <button className="transition duration-500 w-8 h-8 mt-2 hover:bg-red-600 rounded-sm bg-red-400 flex items-center justify-center ml-2 cursor-pointer "
                                    type="button"
                                    onClick={handleClick}
                                >
                                    <MdClose size="30" color='white' />
                                </button>
                            </div>
                            <div className=" flex justify-center items-center">
                                <div className="flex w-full">
                                    <div className="poopins text-md mb-1 mr-1">Link name:</div>
                                    <input
                                        className="poopins w-64 md:w-80 border-2 rounded-sm border-blue-300"
                                        type="text"
                                        id="second "
                                        value={linkName}
                                        maxLength="20"
                                        onChange={(e) => setLinkName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className=" flex justify-center items-center">
                                <div className=" mt-4 flex w-full  ">
                                    <div className="poopins text-md mb-1 mr-12 pr-1">Link :</div>
                                    <input
                                        className="poopins w-64 md:w-80 border-2 rounded-sm border-b-2 border-blue-300 tracking-widest "
                                        type="text"
                                        id="first"
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="w-full flex justify-center">
                                <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-28 border-2 rounded-sm text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold ml-2"
                                    type='submit'
                                >
                                    done
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
            <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-16 h-16 border-2 rounded-full text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold ml-2 flex items-center justify-center" onClick={handleClick} type="button">
                <MdAdd size="30" color="white" />
            </button>
        </div>
    )
}

export default AddLinks
