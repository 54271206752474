import React, { useState, useEffect } from 'react'
import { useParams, Navigate } from "react-router-dom";
import Header from '../components/business/header/Header';
import Rating from '../components/business/rating/Rating';
import Contact from '../components/business/contact/Contact';
import Order from '../components/business/order/Order';
import Social from '../components/business/social/Social';
import Review from '../components/business/review/Review'
import Payments from '../components/business/payments/Payments';
import Footer from '../components/business/footer/Footer';

//amplify grapgql
import { API, graphqlOperation } from 'aws-amplify'

//queries
import { listUsers as ListUsers } from '../graphql/queries';


const Business = () => {
    let params = useParams();
    let businessName = params.name
    const [error, setError] = useState(false)

    //userId
    const [userId, setUserId] = useState("null")

    //getting userId as name changes
    useEffect(() => {
        getPageData(businessName);
    }, [businessName])

    const getPageData = async (businessName) => {
        const userData = await API.graphql(graphqlOperation(ListUsers, { filter: { username: { eq: businessName } } }))
        if (userData.data.listUsers.items.length > 0) {
            setUserId(userData.data.listUsers.items[0].userId)
        } else {
            setError(true)
        }
    }


    if (error) {
        return <Navigate to="/error" />
    }

    return (
        <div className='min-h-screen'>
            {userId &&
                <div className='min-h-screen'>
                    <Header userId={userId} businessName={businessName} />
                    <Payments userId={userId} />
                    <Contact userId={userId} />
                    <Social userId={userId} />


                    {/* <div className='hidden w-full md:flex justify-center items-center'><div className='border-t-2 border-gray-400 w-1/2 md:1/6'></div> </div> */}


                    <Order userId={userId} />


                    <Rating userId={userId} />
                    <Review userId={userId} />

                    <div className='w-full flex justify-center items-center'><div className='pt-1 bg-gray-300 w-1/2 md:1/6 rounded-lg my-6'></div> </div>
                    <Footer />
                </div>
            }
        </div>
    )
}

export default Business
