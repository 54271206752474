import React from 'react'
import { MdClose } from 'react-icons/md'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { deleteReview as DeleteReview } from '../../../graphql/mutations'

const SubReviewAdmin = ({ reviewData, handleRefresh }) => {

    //refresh review
    const handleDelete = async () => {
        const deletedLink = await API.graphql(graphqlOperation(DeleteReview, { input: { reviewId: reviewData.reviewId } }))
        handleRefresh()
    }

    return (

        <div className='md:mx-4 w-52 flex flex-col items-center relative'>
            <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'>
                {/* <img className='w-8 text-white' src={reviewData.review_platform_icon} alt='icon' /> */}
                <div className='text-white text-4xl'>{reviewData.review_from[0].toUpperCase()}</div>
            </div>
            <div className='mt-2 text-center'>{reviewData.review_from}</div>
            <div className='mt-2 text-center'>{reviewData.review_star}/5</div>
            <div className=''>({reviewData.total_review} Reviews)</div>
            <button className="transition duration-500 w-4 h-4 hover:h-6 hover:w-6 hover:bg-red-600 hover:-top-4 hover:right-0 rounded-sm bg-red-400 flex items-center justify-center ml-2 cursor-pointer absolute -top-3 right-2"
                type="button"
                onClick={handleDelete}
            >
                <MdClose size="15" color='white' />
            </button>
        </div>
    )
}

export default SubReviewAdmin
