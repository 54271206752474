import React, { useState, useEffect } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import '../../../styles/global.css'


//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listOwners as ListOwners } from '../../../graphql/queries'
import { createOwner as CreateOwner } from '../../../graphql/mutations'

const AddOwnerAdmin = ({ userId, handleRefresh }) => {

    const [visibleBox, setVisible] = useState(false);
    const [ownerName, setOwnerName] = useState('')
    const [ownerImage, setOwnerImage] = useState('')

    //random linkId
    const OwnerId = Math.random().toString(10) + 'Abc#';

    const handleSubmit = () => {
        handleCreateLink()
        setOwnerName('')
        setOwnerImage('')
        setVisible(!visibleBox);
        handleRefresh()
    }

    const handleClick = () => {
        setVisible(!visibleBox);
    }

    useEffect(() => {
        getOwner("what")
    }, [])

    //gets the owner
    const getOwner = async (userId) => {
        const Owner = await API.graphql(graphqlOperation(ListOwners, { filter: { userOwnerId: { eq: userId } } }))
        Owner.data.listOwners.items[0] !== false && handleRefresh();
    }

    const handleCreateLink = async () => {
        try {
            const ownerCreated = await API.graphql(graphqlOperation(CreateOwner,
                { input: { ownerId: OwnerId, owner_name: ownerName, userOwnerId: userId, owner_image: ownerImage } }))
            console.log(ownerCreated.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            {visibleBox &&
                <div className='top-0 left-0 fixed bg-gray-800/50 min-h-screen w-full flex justify-center items-center'>
                    <form onSubmit={handleSubmit}>
                        <div className='bg-white rounded-sm px-4 '>
                            <div className='flex'>
                                <div className="poopins text-xl font-bold w-full  text-center pt-4 mb-6">Owner Details</div>
                                <button className="transition duration-500 w-8 h-8 mt-2 hover:bg-red-600 rounded-sm bg-red-400 flex items-center justify-center ml-2 cursor-pointer "
                                    type="button"
                                    onClick={handleClick}
                                >
                                    <MdClose size="30" color='white' />
                                </button>
                            </div>
                            <div className=" flex justify-center items-center">
                                <div className="flex w-full justify-between">
                                    <div className="poopins text-md mb-1 mr-1">Owner Name:</div>
                                    <input
                                        className="poopins w-64 md:w-80 border-2 rounded-sm border-blue-300"
                                        type="text"
                                        id="second "
                                        value={ownerName}
                                        onChange={(e) => setOwnerName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className=" flex justify-center items-center">
                                <div className=" mt-4 flex w-full justify-between ">
                                    <div className="poopins text-md mb-1 mr-12 pr-1">Owner Image :</div>
                                    <input
                                        className="poopins w-64 md:w-80 border-2 rounded-sm border-b-2 border-blue-300 tracking-widest "
                                        type="text"
                                        id="first"
                                        value={ownerImage}
                                        onChange={(e) => setOwnerImage(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="w-full flex justify-center">
                                <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-28 border-2 rounded-sm text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold ml-2"
                                    type='submit'
                                >
                                    done
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
            <div className='text-2xl'>Owner</div>
            <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-16 h-16 border-2 rounded-full text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold ml-2 flex items-center justify-center" onClick={handleClick} type="button">
                <MdAdd size="30" color="white" />
            </button>
        </div>
    )
}

export default AddOwnerAdmin
