import React, { useState, useEffect } from 'react'
import AddOwnerAdmin from './AddOwnerAdmin'
import { MdClose } from 'react-icons/md'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listOwners as ListOwners } from '../../../graphql/queries'
import { deleteOwner as DeleteOwner } from '../../../graphql/mutations'


const OwnerAdmin = ({ userId }) => {

    const [ownerData, setOwnerData] = useState({});
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getOwner(userId)
    }, [refresh])

    //toggles refresh variable to trigger useEffect 
    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    //gets the owner
    const getOwner = async (userId) => {
        const Owner = await API.graphql(graphqlOperation(ListOwners, { filter: { userOwnerId: { eq: userId } } }))

        console.log(Owner.data.listOwners.items[0])
        Owner.data.listOwners.items[0] !== false ?
            setOwnerData(Owner.data.listOwners.items[0]) :
            setOwnerData({})
    }

    //deletes the owner
    const handleDelete = async () => {
        const deletedLink = await API.graphql(graphqlOperation(DeleteOwner, { input: { ownerId: ownerData.ownerId } }))
        handleRefresh();
    }

    return (
        <div className='w-full mt-28'>
            {ownerData
                ?
                <div className='w-full flex flex-col items-center '>
                    <div className='h-20 w-20 rounded-full bg-blue-300 relative'>
                        {/* {ownerData.owner_image} */}
                        <button className="transition duration-500 w-4 h-4 hover:h-6 hover:w-6 hover:bg-red-600 hover:-top-4 hover:-right-4 rounded-sm bg-red-400 flex items-center justify-center ml-2 cursor-pointer absolute -top-3 -right-3"
                            type="button"
                            onClick={handleDelete}
                        >
                            <MdClose size="15" color='white' />
                        </button>
                    </div>
                    <div>Owner</div>
                    <div className='text-2xl'>
                        {ownerData.owner_name}
                    </div>

                </div>
                :
                <div className='w-full mt-10 flex items-center justify-center'>
                    <AddOwnerAdmin userId={userId} handleRefresh={handleRefresh} />
                </div>
            }
        </div>

    )
}

export default OwnerAdmin
