import React, { useEffect, useState } from 'react'
import SubOrderAdmin from './SubOrderAdmin'
import AddLinks from '../addLink/AddLinks'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const OrderAdmin = ({ userId }) => {

    const LinkType = "orders"
    const [refresh, setRefresh] = useState(false)
    const [orderData, setOrderData] = useState([])

    useEffect(() => {
        getOrderLinks(userId)
    }, [refresh])

    //toggles refresh variable to trigger useEffect 
    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const getOrderLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setOrderData(Links.data.listLinks.items)
    }

    return (
        <div className='w-full flex items-center justify-center'>
            <div className='flex flex-col items-center my-2 md:my-6 w-full md:w-1/2'>
                <div className='md:my-16 flex flex-col items-center  w-full '>
                    <div className='md:w-full'>
                        <div className='text-2xl md:text-3xl text-center w-full'>Order Online</div>
                        <div className='text-center w-full'>Feel free to chat with us!</div>
                    </div>
                    <div className='flex flex-wrap justify-center  w-full mt-4'>
                        {orderData.map((order) => {
                            return (
                                <SubOrderAdmin orderData={order} key={order.linkId}
                                    handleRefresh={handleRefresh} />
                            )
                        })}
                    </div>
                </div>
                <AddLinks type={LinkType} userId={userId} handleRefresh={handleRefresh} />
            </div>
        </div>
    )
}

export default OrderAdmin