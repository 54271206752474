import React, { useEffect, useState } from 'react'
import SubPayment from './SubPayment'

import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const Payments = ({ userId }) => {

    const [paymentsData, setPaymentsData] = useState(null);
    const LinkType = "payments"

    useEffect(() => {
        getPaymentLinks(userId);
    }, [userId])

    const getPaymentLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setPaymentsData(Links.data.listLinks.items)
        if (Links.data.listLinks.items.length === 0) {
            setPaymentsData(null)
        }
    }

    return (
        <div className='w-full flex items-center justify-center '>
            {paymentsData !== null &&
                <div className='w-full md:w-1/2'>
                    <div className=' md:my-16 flex flex-col items-center'>
                        <div className='w-full'>
                            <div className='text-2xl md:text-3xl w-full text-center'>Payments</div>
                        </div>
                        <div className='flex flex-wrap justify-center w-full mt-2 md:mt-4'>
                            {paymentsData.map((payment) => {
                                return (
                                    <SubPayment paymentsData={payment} key={payment.link_name} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Payments
