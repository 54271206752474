import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Business from "./pages/Business";
import Error from "./pages/404.js";
import Landing from "./pages/Landing"
import LogIn from "./pages/admin/LogIn";
import Dashboard from "./pages/admin/Dashboard";
import UserDetail from "./pages/admin/UserDetail";
import { AdminProvider } from './context/AdminContext'



function App() {
  return (
    <Router>
      <AdminProvider>
        <Routes>
          <Route path="/admin/login" exact element={<LogIn />} />
          <Route path="/admin/userdetail" exact element={<UserDetail />} />
          <Route path="/admin/dashboard" exact element={<Dashboard />} />
          <Route path="/" exact element={<Landing />} />
          <Route path="/:name" exact element={<Business />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </AdminProvider>
    </Router>
  );
}

export default App;
