import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MdDone, MdClose } from 'react-icons/md'
import '../../styles/global.css'
//context
import { AdminContext } from '../../context/AdminContext'


//amplify
import { API, Auth, graphqlOperation } from 'aws-amplify'
// import { Auth } from "aws-amplify"

//queries
import { listUsers as ListUsers } from '../../graphql/queries'
import { createUser as CreateUser } from '../../graphql/mutations'


const UserDetail = () => {
    const navigate = useNavigate();
    const [user, SetUser] = useState({})
    const [username, setUsername] = useState('')
    const [available, setAvailable] = useState(false)
    const [arrayUsername, setArrayUsername] = useState([]);

    //context
    const [adminData, setAdminData] = useContext(AdminContext)

    const handleChangeUsername = (e) => {
        setUsername(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let userId = user.username
        let phoneNumber = user.attributes.phone_number
        handleCreateUser(username, userId, phoneNumber);
    }

    const handleCreateUser = async (username, userId, phoneNumber) => {
        try {
            const createdUser = await API.graphql(graphqlOperation(CreateUser, {
                input: { userId: userId, phone_number: phoneNumber, username: username }
            }))
            setAdminData({ ...adminData, "user": createdUser.data.createUser.userId })
            if (createdUser.data.createUser !== 0) {
                navigate("/admin/dashboard");
            } else {
                console.log("username unavailable")
            }
            console.log(createdUser)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        currentUser();

        checkUsername(username);
    }, [username])

    const checkUserUserNameExits = async (userId) => {
        try {
            const UserExits = await API.graphql(graphqlOperation(ListUsers, { filter: { userId: { eq: userId } } }))
            if (UserExits.data.listUsers.items.length !== 0) {
                navigate("/admin/dashboard")
                console.log(UserExits.data.listUsers.items)
                console.log(userId)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const currentUser = () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                if ("attributes" in user === true) {
                    Auth.currentUserInfo().then((user) => { SetUser(user) })
                    checkUserUserNameExits(user.username);
                } else {
                    navigate("/admin/login");
                    console.log('error')
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const checkUsername = async (username) => {
        try {
            const listOfUsername = await API.graphql(graphqlOperation(ListUsers, { filter: { username: { eq: username } } }))
            setArrayUsername(listOfUsername.data.listUsers.items)
            if (listOfUsername.data.listUsers.items.length === 0) {
                setAvailable(true)
            }
            else {
                setAvailable(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='min-h-screen w-full flex items-center justify-center bg-gray-200'>
            <form className='transition duration-700 md:-translate-y-16' onSubmit={handleSubmit}>
                <div className='uppercase font-semibold text-center text-2xl tracking-wider text-gray-600 mt-16 mb-4'>enter your credentials</div>
                <div className='bg-white rounded-sm px-4'>
                    <div className="poopins text-xl font-bold w-full  text-center pt-4">USERINFO</div>
                    <div className="poopins text-md mb-1">Check Availability</div>

                    <div className=" flex justify-center items-center">
                        <div className=" mt-4 flex w-full ">
                            <div className="poopins text-md mb-1 mr-3">intrnet.pe/</div>
                            <input
                                className="poopins w-64 md:w-80 border-2 rounded-sm border-b-2 border-blue-300 tracking-widest "
                                type="text"
                                id="first"
                                minLength="3"
                                maxLength="15"
                                required
                                value={username}
                                onChange={handleChangeUsername}
                            />{available ?
                                <div className="w-8 h-8 rounded-sm bg-green-400 flex items-center justify-center ml-2 cursor-pointer">
                                    <MdDone size="24" color='white' />
                                </div>
                                :
                                <div className="w-8 h-8 rounded-sm bg-red-400 flex items-center justify-center ml-2 cursor-pointer">
                                    <MdClose size="24" color='white' />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full flex justify-center">

                        <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-28 border-2 rounded-sm text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold"
                            type="submit"
                            disabled={!available}>
                            Done
                        </button>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default UserDetail
