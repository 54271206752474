/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userId
      name
      phone_number
      username
      header {
        nextToken
      }
      review {
        nextToken
      }
      owner {
        nextToken
      }
      Links {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userId
      name
      phone_number
      username
      header {
        nextToken
      }
      review {
        nextToken
      }
      owner {
        nextToken
      }
      Links {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userId
      name
      phone_number
      username
      header {
        nextToken
      }
      review {
        nextToken
      }
      owner {
        nextToken
      }
      Links {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHeader = /* GraphQL */ `
  mutation CreateHeader(
    $input: CreateHeaderInput!
    $condition: ModelHeaderConditionInput
  ) {
    createHeader(input: $input, condition: $condition) {
      id
      HeaderId
      header_image
      profile_image
      name
      createdAt
      updatedAt
      userHeaderId
    }
  }
`;
export const updateHeader = /* GraphQL */ `
  mutation UpdateHeader(
    $input: UpdateHeaderInput!
    $condition: ModelHeaderConditionInput
  ) {
    updateHeader(input: $input, condition: $condition) {
      id
      HeaderId
      header_image
      profile_image
      name
      createdAt
      updatedAt
      userHeaderId
    }
  }
`;
export const deleteHeader = /* GraphQL */ `
  mutation DeleteHeader(
    $input: DeleteHeaderInput!
    $condition: ModelHeaderConditionInput
  ) {
    deleteHeader(input: $input, condition: $condition) {
      id
      HeaderId
      header_image
      profile_image
      name
      createdAt
      updatedAt
      userHeaderId
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      reviewId
      review_from
      review_star
      total_review
      review_link
      review_platform_icon
      createdAt
      updatedAt
      userReviewId
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      reviewId
      review_from
      review_star
      total_review
      review_link
      review_platform_icon
      createdAt
      updatedAt
      userReviewId
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      reviewId
      review_from
      review_star
      total_review
      review_link
      review_platform_icon
      createdAt
      updatedAt
      userReviewId
    }
  }
`;
export const createOwner = /* GraphQL */ `
  mutation CreateOwner(
    $input: CreateOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    createOwner(input: $input, condition: $condition) {
      ownerId
      owner_name
      owner_image
      createdAt
      updatedAt
      userOwnerId
    }
  }
`;
export const updateOwner = /* GraphQL */ `
  mutation UpdateOwner(
    $input: UpdateOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    updateOwner(input: $input, condition: $condition) {
      ownerId
      owner_name
      owner_image
      createdAt
      updatedAt
      userOwnerId
    }
  }
`;
export const deleteOwner = /* GraphQL */ `
  mutation DeleteOwner(
    $input: DeleteOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    deleteOwner(input: $input, condition: $condition) {
      ownerId
      owner_name
      owner_image
      createdAt
      updatedAt
      userOwnerId
    }
  }
`;
export const createLinks = /* GraphQL */ `
  mutation CreateLinks(
    $input: CreateLinksInput!
    $condition: ModelLinksConditionInput
  ) {
    createLinks(input: $input, condition: $condition) {
      linkId
      link_type
      link_name
      link_icon
      link
      createdAt
      updatedAt
      userLinksId
    }
  }
`;
export const updateLinks = /* GraphQL */ `
  mutation UpdateLinks(
    $input: UpdateLinksInput!
    $condition: ModelLinksConditionInput
  ) {
    updateLinks(input: $input, condition: $condition) {
      linkId
      link_type
      link_name
      link_icon
      link
      createdAt
      updatedAt
      userLinksId
    }
  }
`;
export const deleteLinks = /* GraphQL */ `
  mutation DeleteLinks(
    $input: DeleteLinksInput!
    $condition: ModelLinksConditionInput
  ) {
    deleteLinks(input: $input, condition: $condition) {
      linkId
      link_type
      link_name
      link_icon
      link
      createdAt
      updatedAt
      userLinksId
    }
  }
`;
