import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../../styles/global.css'

//Auth

import { Auth } from "aws-amplify"
import Amplify from 'aws-amplify'
import config from '../../aws-exports'
Amplify.configure(config)

//custom message

const NOTSIGNIN = 'You are NOT logged in';
const SIGNEDIN = 'You have logged in successfully';
// const SIGNEDOUT = 'You have logged out successfully';
const WAITINGFOROTP = 'Enter OTP number';
const VERIFYNUMBER = 'Verifying number';


const LogIn = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('Welcome');
    const [number, setNumber] = useState("")
    const [otp, setOtp] = useState("");
    const [loginButton, setLogButton] = useState(false);
    const password = Math.random().toString(10) + 'Abc#';
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);

    const handleChange = (e) => {
        setNumber(e.target.value.replace(/\D/g, ""));
    };

    const handleChangeOtp = (e) => {
        setOtp(e.target.value.replace(/\D/g, ""));
    };


    const signUp = async () => {
        const result = await Auth.signUp({
            username: "+91" + number,
            password,
            attributes: {
                phone_number: "+91" + number,
            },
        }).then(() => signIn()); // After signUp, we are going to signIn() 
        return result;
    };

    const signIn = () => {
        setMessage(VERIFYNUMBER);
        Auth.signIn("+91" + number)
            .then((result) => {
                setSession(result);
                setMessage(WAITINGFOROTP);
            })
            .catch((e) => {
                if (e.code === 'UserNotFoundException') {
                    signUp();
                } else if (e.code === 'UsernameExistsException') {
                    setMessage(WAITINGFOROTP);
                    signIn();
                } else {
                    console.log(e.code);
                    console.error(e);
                }
            });
        setLogButton(true);
    }

    const verifyOtp = (e) => {
        e.preventDefault();
        Auth.sendCustomChallengeAnswer(session, otp)
            .then((user) => {
                if ("attributes" in user == true) {
                    setUser(user) // this is THE cognito user 
                    setMessage(SIGNEDIN)
                    setSession(null)
                    navigate("/admin/userdetail");
                } else {
                    setMessage('incorrect otp')
                    setOtp('');
                }
            })
            .catch((err) => {
                setMessage(err.message);
                setOtp('');
                console.log(err);
            });
    };

    // const signOut = () => {
    //     if (user) {
    //         Auth.signOut();
    //         setUser(null);
    //         setOtp('');
    //         setMessage(SIGNEDOUT);
    //     } else {
    //         setMessage(NOTSIGNIN);
    //     }
    // };

    useEffect(() => {
        verifyAuth()
    }, []);

    const verifyAuth = () => {

        Auth.currentAuthenticatedUser()
            .then((user) => {
                if ("attributes" in user == true) {

                    setUser(user);
                    setMessage(SIGNEDIN);
                    setSession(null);
                    navigate("/admin/userdetail");

                } else {
                    navigate("/admin/login");
                    console.log('error')
                }
            })
            .catch((err) => {
                console.error(err);
                setMessage(NOTSIGNIN);
            });
    };

    return (
        <div className='min-h-screen w-full flex items-center justify-center bg-gray-200'>
            <form className='transition duration-700  md:-translate-y-16' onSubmit={verifyOtp}>
                <div className='uppercase font-semibold text-center text-2xl tracking-wider text-gray-600 mt-16 mb-4'>log in  to create your own page !</div>
                <div className='bg-white rounded-sm px-4'>
                    <div className="poopins text-xl font-bold w-full  text-center pt-4">LOGIN</div>
                    <div className="poopins text-md mb-1">Enter your number</div>
                    <div className=" flex justify-center items-center">
                        <div className="flex w-full">
                            <input
                                className="poopins text-center w-20  rounded-sm border-blue-300"
                                type="text"
                                id="first"
                                maxLength="5"
                                value="+91 "
                                disabled
                            />
                            <input
                                className="poopins w-64 md:w-80 border-2 rounded-sm border-blue-300"
                                type="text"
                                id="second "
                                maxLength="10"
                                value={number}
                                onChange={handleChange}
                                required
                                disabled={loginButton}
                            />
                        </div>
                    </div>
                    <div>{
                        loginButton && <div className=" mt-4 flex ">
                            <div className="poopins text-md mb-1 mr-1">Enter otp :</div>
                            <input
                                className="poopins w-28 border-2 rounded-sm text-center border-b-2 border-blue-300 tracking-widest px-4"
                                type="text"
                                id="first"
                                maxLength="6"
                                required
                                value={otp}
                                onChange={handleChangeOtp}
                            />
                        </div>
                    }

                    </div>
                    <div className="w-full flex justify-center">
                        {loginButton ?
                            <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-28 border-2 rounded-sm text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold"
                                type="submit"
                            >LOGIN</button> :
                            <button className="tansition duration-500 bg-blue-300 text-white hover:scale-110  mt-6 mb-6 poopins  w-28 border-2 rounded-sm text-center border-b-2 border-blue-300 tracking-widest py-2 px-4 uppercase font-bold"
                                type="button"
                                onClick={signIn}>get otp</button>}

                    </div>
                </div>
                {/* <div>{message}</div> */}
            </form>
            {/* <button onClick={signOut}>signOut</button> */}
        </div >
    )
}

export default LogIn
