import React, { useEffect, useState } from 'react'
import SubRating from './SubRating'

import { API, graphqlOperation } from 'aws-amplify'
import { listReviews as ListReviews } from '../../../graphql/queries'

const Rating = ({ userId }) => {

    const [ratingData, setRatingData] = useState(null);

    useEffect(() => {
        getReviewLinks(userId);
    }, [userId])

    const getReviewLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListReviews, { filter: { userReviewId: { eq: userId } } }))
        setRatingData(Links.data.listReviews.items)
        if (Links.data.listReviews.items.length === 0) {
            setRatingData(null)
        }
    }


    return (
        <>
            {ratingData !== null &&
                <div className='w-full md:h-52  mt-2 md:mt-8 '>

                    <div className='text-2xl md:text-3xl w-full text-center'>Ratings</div>
                    <div className='w-full mt-2 flex items-center justify-center'>
                        {ratingData.map((rating) => {
                            return (
                                <SubRating ratingData={rating} key={rating.review_from} />
                            )
                        })}
                    </div>
                </div>
            }
        </>
    )
}

export default Rating
