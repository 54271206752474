import React, { useEffect, useState } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listOwners as ListOwners } from '../../../graphql/queries'

const Review = ({ userId }) => {
    const [ownerData, setOwnerData] = useState(null);

    useEffect(() => {
        getOwner(userId)
    }, [userId])

    const getOwner = async (userId) => {
        const Owner = await API.graphql(graphqlOperation(ListOwners, { filter: { userOwnerId: { eq: userId } } }))

        console.log(Owner.data.listOwners.items[0])
        Owner.data.listOwners.items[0] ?
            setOwnerData(Owner.data.listOwners.items[0]) :
            setOwnerData(null)
    }

    return (
        <>
            {ownerData !== null &&
                <div className='w-full mt-28'>
                    <div className='w-full flex flex-col items-center'>
                        <div className='h-20 w-20 rounded-full bg-blue-300 flex items-center justify-center'>
                            <div className='text-white text-4xl'>{ownerData.owner_name[0].toUpperCase()}</div>
                            {/* {reviewData.owner_image} */}
                        </div>
                        <div>Owner</div>
                        <div className='text-2xl'>
                            {ownerData.owner_name}
                        </div>
                    </div>
                    <div className='w-full mt-10 flex items-center justify-center'>
                        <div className='w-3/4 md:w-1/2'>
                            <div>Write us a review!</div>
                            <textarea className='w-full mt-2 border-2 rounded-2xl px-4 py-2' placeholder='type something' />
                            <div className='flex flex-row-reverse w-full '>
                                <button className='bg-blue-600 rounded-2xl px-4 text-white font-semibold py-1 mt-2' type="button">submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Review
