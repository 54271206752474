import React, { useEffect, useState } from 'react'
import SubContact from './SubContact'

import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const Contact = ({ userId }) => {

    const [contactData, setContactData] = useState(null)
    const LinkType = "contacts"

    useEffect(() => {
        getContactLinks(userId)
    }, [userId])

    const getContactLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setContactData(Links.data.listLinks.items)
        if (Links.data.listLinks.items.length === 0) {
            setContactData(null)
        }
    }

    return (
        <div className='w-full flex items-center justify-center '>
            {contactData !== null &&
                <div className='flex flex-col items-center my-6 w-full md:w-1/2 '>
                    <div className='md:my-16 flex flex-col items-center  w-full'>
                        <div className='md:w-full '>
                            <div className='text-2xl md:text-3xl text-center w-full'>Contact</div>
                            {/* <div className='text-center w-full'>Feel free to chat with us!</div> */}
                        </div>
                        <div className='flex flex-wrap justify-center  w-full mt-4'>
                            {contactData.map((contact) => {
                                return (
                                    <SubContact contactData={contact} key={contact.link_name} />
                                )
                            })}
                        </div>
                    </div>
                    {/* <div className='bg-blue-200 h-60 w-60 rounded-2xl'></div> */}
                </div>
            }
        </div>
    )
}

export default Contact
