import React, { useState, useEffect } from 'react'

const SubSocial = ({ socialData }) => {
    const [link, setLink] = useState(socialData.link)

    useEffect(() => {
        linkUpdate();
    }, []);

    const linkUpdate = () => {
        if (link.substr(0, 3) !== "http" && link.substr(0, 1) !== "//") {
            setLink("//" + link)

        }
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <div className=''>
                <div className='mx-4 my-4 w-28 flex flex-col items-center'>
                    <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'>
                        {/* <img className='w-12' src={socialData.link_icon} alt='icon' /> */}
                        <div className='text-white text-4xl'>{socialData.link_name[0].toUpperCase()}</div>
                    </div>
                    <div className='mt-2 text-center'>{socialData.link_name}</div>
                </div>
            </div>
        </a>
    )
}

export default SubSocial
