import React, { useState, useEffect } from 'react'
import SubReviewAdmin from './SubReviewAdmin'
import AddReview from './AddReview'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listReviews as ListReviews } from '../../../graphql/queries'

const ReviewAdmin = ({ userId }) => {

    const [reviewData, setReviewData] = useState([]);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getReviewLinks();
    }, [refresh])

    //toggles refresh variable to trigger useEffect 
    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const getReviewLinks = async () => {
        const Links = await API.graphql(graphqlOperation(ListReviews, { filter: { userReviewId: { eq: userId } } }))
        setReviewData(Links.data.listReviews.items)
    }

    return (
        <div className='w-full  mt-2 md:mt-8'>
            <div className='text-2xl md:text-3xl w-full text-center'>Ratings</div>
            <div className='w-full mt-2 flex items-center justify-center'>
                {reviewData.map((review) => {
                    return (
                        <SubReviewAdmin reviewData={review} key={review.reviewId} handleRefresh={handleRefresh} />
                    )
                })}
            </div>
            <div className='w-full mt-2 flex items-center justify-center'>
                <AddReview userId={userId} handleRefresh={handleRefresh} />
            </div>
        </div>
    )
}

export default ReviewAdmin
