import React, { useState, createContext } from "react";

export const AdminContext = createContext();

export const AdminProvider = (props) => {
    const [adminData, setAdminData] = useState({
        "user": {
            "username": null
        },
        "owner": {
            "name": "91kitchen",
            "owner_name": null,
            "owner_image": null,
            "phone_number": "9818505159"
        },
        "assets": {
            "header_pic": null,
            "profile_pic": null,
            "business_name": null
        },
        "reviews": [
            {
                "review_from": "Swiggy",
                "review_stars": 4.8,
                "total_reviews": 44,
                "review_link": "https://www.swiggy.com/restaurants/the-kitchen-story-sohna-road-gurgaon-447609",
                "review_platform_icon": null
            }
        ],
        "order": [
            {
                "link_type": "Order Online",
                "link": "https://www.zomato.com/ncr/91-kitchen-sector-29",
                "link_icon": "/media/27675-ai.png",
                "link_name": "Zomato",
                "created_at": "2021-12-12T09:57:12.457132Z",
                "updated_at": "2021-12-12T09:57:12.457153Z"
            }
        ],
        "social": [
            {
                "link_type": "Social",
                "link": "https://www.facebook.com/91Kitchen/",
                "link_icon": "/media/WhatsApp_Image_2021-10-21_at_22.40.08.jpeg",
                "link_name": "Facebook",
                "created_at": "2021-12-12T09:57:47.539330Z",
                "updated_at": "2021-12-12T09:57:47.539350Z"
            }
        ],
        "contact": [
            {
                "link_type": "Contact",
                "link": "9835166667",
                "link_icon": "/media/Dev..svg",
                "link_name": "call",
                "created_at": "2021-12-12T09:56:29.120760Z",
                "updated_at": "2021-12-12T10:46:52.435612Z"
            }
        ],
        "payment": [
            {
                "link_type": "Payment",
                "link": "https://workspace.google.com/static/img/google-workspace-logo.svg",
                "link_icon": "/media/unnamed_CCfauqL.png",
                "link_name": "Paytm",
                "created_at": "2021-12-12T09:59:17.961766Z",
                "updated_at": "2021-12-12T09:59:17.961788Z"
            }
        ]
    });

    return (
        <AdminContext.Provider value={[adminData, setAdminData]}>
            {props.children}
        </AdminContext.Provider>
    );
}