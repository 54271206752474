import React, { useEffect, useState } from 'react'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listHeaders as ListHeader } from '../../../graphql/queries'


const Header = ({ userId, businessName }) => {

    const [headerData, setHederData] = useState(null)

    useEffect(() => {
        if (userId) {
            getHeaderData(userId);
        }
    }, [userId])

    const getHeaderData = async (userId) => {
        try {
            const headerData = await API.graphql(graphqlOperation(ListHeader, {
                filter: { userHeaderId: { eq: userId } }
            }))
            if (headerData.data.listHeaders.items.length > 0) {
                setHederData(headerData.data.listHeaders.items[0]);
                // console.log(headerData.data.listHeaders.items[0]);
                //to be build when userId is undefined
            }
            console.log(headerData + userId)
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className=''>
            {headerData !== null &&
                <div>
                    <div className=' w-full h-50 md:h-80 flex justify-center items-center overflow-hidden'>
                        <img className='w-full md:w-1/2 aspect-auto' src={headerData.header_image} alt='header_pic' />
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <div className='duration-500 w-28 h-28 md:h-52 md:w-52 rounded-2xl bg-indigo-400 transition -translate-y-16 overflow-hidden'>
                            <img className='w-28 h-28 md:h-52 md:w-52 ' src={headerData.profile_image} alt='profile_pic' />
                        </div>
                    </div>
                    <div className='duration-500 transition -translate-y-16 md:translate-y-0 w-full text-center text-4xl font-semibold'>{businessName}</div>
                </div>
            }
        </div>
    )
}

export default Header


// 
