import React, { useState, useEffect } from 'react'


const SubOrder = ({ orderData }) => {
    const [link, setLink] = useState(orderData.link)

    useEffect(() => {
        linkUpdate();
    }, []);

    const linkUpdate = () => {
        if (link.substr(0, 3) !== "http" && link.substr(0, 1) !== "//") {
            setLink("//" + link)
        }
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <div className='mx-4 my-4 w-28 flex flex-col items-center'>
                <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'>
                    {/* <img className='w-12' src={orderData.link_icon} alt='icon' /> */}
                    <div className='text-white text-4xl'>{orderData.link_name[0].toUpperCase()}</div>
                </div>
                <div className='mt-2 text-center'>{orderData.link_name}</div>
            </div>
        </a>
    )
}

export default SubOrder
