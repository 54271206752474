import React, { useState, useEffect } from 'react'
import { MdAdd } from 'react-icons/md'
import '../../styles/global.css'

//amplify 
import { Storage, API, graphqlOperation } from 'aws-amplify';
import { getUser as GetUser } from '../../graphql/queries';
import { listHeaders as ListHeader } from '../../graphql/queries';
import { createHeader as CreateHeader } from '../../graphql/mutations';
import { updateHeader as UpadateHeader } from '../../graphql/mutations';

const HeaderAdmin = ({ userId }) => {

    //temp UserId
    // const userId = '+917903010650'

    //data
    const [data, setData] = useState({})

    //username, userId states
    const [username, setUsername] = useState('');

    //refresh
    const [refresh, setRefresh] = useState(false);

    //disabling header image input
    const [disableHeaderInput, setDisableHeaderInput] = useState(false);

    //disabling profile image input 
    const [disableProfileInput, setDisableProfileInput] = useState(false)

    //header progress
    const [headerProgress, setHeaderProgress] = useState(0);
    const [profileProgress, setProfileProgress] = useState(0);



    useEffect(() => {
        //check if header exists
        getHeaderData();

        //get username
        getUserData();

    }, [])

    useEffect(() => {
        getHeaderData();

    }, [refresh])

    // getting header data check existence
    const getHeaderData = async () => {
        try {
            const headerData = await API.graphql(graphqlOperation(ListHeader, {
                filter: { userHeaderId: { eq: userId } }
            }))
            if (headerData.data.listHeaders.items.length > 0) {
                setData(headerData.data.listHeaders.items[0]);
                console.log(headerData.data.listHeaders.items)
            } else {
                //create header if doesnot exists
                createHeader();
                getHeaderData();
            }
        } catch (err) {
            console.log(err);
        }
    }

    // creating if doesnot exist
    const createHeader = async () => {
        try {
            await API.graphql(graphqlOperation(CreateHeader, {
                input: { HeaderId: userId, userHeaderId: userId, id: userId }
            }))
        } catch (err) {
            console.log(err)
        }
    }

    const getUserData = async () => {
        try {
            const userData = await API.graphql(graphqlOperation(GetUser, {
                userId: userId
            }))
            setUsername(userData.data.getUser.username)
            console.log('username' + userData.data.getUser.username)
        } catch (err) {
            console.log(err)
        }
    }

    // -------------------------------------------------
    //setting header area image

    const getSetHeaderImageUrl = async (filename) => {
        const url = await Storage.get(filename);
        setHederUrl(filename)
    }

    const setHederUrl = async (filename) => {
        try {
            const newFilename = filename.slice(1, filename.length)

            const headerUrl = `https://intrnetpe6452703b871d4a69be14f11178c3af1f110256-dev.s3.ap-south-1.amazonaws.com/public/%2B${newFilename}`

            const updatedHeaderImage = await API.graphql(graphqlOperation(UpadateHeader,
                {
                    input: {
                        id: userId,
                        header_image: headerUrl
                    }
                }
            ))
            console.log(updatedHeaderImage)
        } catch (err) {
            console.log(err)
        }
    }

    // --------------------------------------------------


    // amplify storage upload file
    const handleChangeHeaderImage = async (e) => {
        const filename = userId + 'headerImages'
        try {
            const result = await Storage.put(filename, e.target.files[0], {
                progressCallback(progress) {
                    setHeaderProgress(progress.loaded / progress.total * 100);
                },
            })
            setDisableHeaderInput(true)
            console.log(result)
        } catch (err) {
            console.log(err)
        }
        getSetHeaderImageUrl(filename);
    }

    //-----------profile-------------
    const getSetProfileImageUrl = async (filename) => {
        const url = await Storage.get(filename);
        setProfileUrl(filename)
    }
    const setProfileUrl = async (filename) => {
        try {
            const newFilename = filename.slice(1, filename.length)

            const imageUrl = `https://intrnetpe6452703b871d4a69be14f11178c3af1f110256-dev.s3.ap-south-1.amazonaws.com/public/%2B${newFilename}`

            const updatedProfileImage = await API.graphql(graphqlOperation(UpadateHeader,
                {
                    input: {
                        id: userId,
                        profile_image: imageUrl
                    }
                }
            ))
            console.log(updatedProfileImage)
            setRefresh(!refresh);
        } catch (err) {
            console.log(err)
        }
    }


    const handleChangeProfileImage = async (e) => {
        const filename = userId + 'profileImages'
        try {
            const result = await Storage.put(filename, e.target.files[0], {
                progressCallback(progress) {
                    setProfileProgress(progress.loaded / progress.total * 100);
                },
            })
            setDisableProfileInput(true)
            console.log(result)
        } catch (err) {
            console.log(err)
        }
        getSetProfileImageUrl(filename);
    }


    console.log(data)
    return (
        <div className='w-full h-auto'>
            <div className=' w-full h-52 md:h-80 flex justify-center items-center overflow-hidden'>
                {data.header_image === null ?
                    <label className="bg-gray-200 h-52 md:h-80 w-full md:w-1/2 flex justify-center items-center"
                    >
                        {headerProgress === 0 ?
                            <MdAdd size="60" color="#d3d7de" />
                            :
                            <div>
                                <progress className='w-80 h-2.5' value={headerProgress} max="100"></progress>
                                {headerProgress === 100 &&
                                    <div className='text-green-400 font-semibold'>upload complete !</div>
                                }
                            </div>
                        }
                        <input type="file" alt="photo" accept="image/*" required
                            onChange={handleChangeHeaderImage} disabled={disableHeaderInput} hidden></input>
                    </label>
                    :
                    <img className='w-full md:w-1/2 aspect-auto' src={data.header_image} alt='header_pic' />
                }
            </div>
            <div className='w-full flex flex-row justify-center'>
                {data.profile_image === null ?
                    <label className="duration-500 w-28 h-28 md:h-52 md:w-52 rounded-2xl bg-indigo-400 transition -translate-y-16 overflow-hidden flex justify-center items-center"
                    >
                        {profileProgress === 0 ?
                            <MdAdd size="60" color="#d3d7de" />
                            :
                            <div>
                                <progress className='w-26 h-2.5' value={profileProgress} max="100"></progress>
                                {profileProgress === 100 &&
                                    <div className='text-green-400 font-semibold'>upload complete !</div>
                                }
                            </div>
                        }
                        <input type="file" alt="photo" accept="image/*" required onChange={handleChangeProfileImage} disabled={disableProfileInput} hidden></input>
                    </label>
                    :
                    <div className='duration-500 w-28 h-28 md:h-52 md:w-52 rounded-2xl bg-indigo-400 transition -translate-y-16 overflow-hidden'>
                        <img className='w-28 h-28 md:h-52 md:w-52 ' src={data.profile_image} alt='profile_pic' />
                    </div>
                }
            </div>
            <div className='duration-500 transition -translate-y-16 md:translate-y-0 w-full text-center text-4xl font-semibold'>{username}</div>
        </div>
    )
}

export default HeaderAdmin
