import React, { useEffect, useState } from 'react'
import SubContactAdmin from './SubContactAdmin'
import AddLinks from '../addLink/AddLinks'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const ContactAdmin = ({ userId }) => {
    const LinkType = "contacts"
    const [refresh, setRefresh] = useState(false)
    const [contactData, setContactData] = useState([])

    useEffect(() => {
        getContactLinks(userId)
    }, [refresh])

    //toggles refresh variable to trigger useEffect 
    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const getContactLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setContactData(Links.data.listLinks.items)
    }

    return (
        <div className='w-full flex items-center justify-center '>
            <div className='flex flex-col items-center my-6 w-full md:w-1/2 '>
                <div className='md:my-16 flex flex-col items-center  w-full'>
                    <div className='md:w-full '>
                        <div className='text-2xl md:text-3xl text-center w-full'>Contact</div>
                        <div className='text-center w-full'>Feel free to chat with us!</div>
                    </div>
                    <div className='flex flex-wrap justify-center  w-full mt-4'>
                        {contactData.map((contact) => {
                            return (
                                <SubContactAdmin contactData={contact} key={contact.linkId} handleRefresh={handleRefresh} />
                            )
                        })}
                    </div>
                    <AddLinks type="contacts" userId={userId} handleRefresh={handleRefresh} />
                </div>
                {/* <div className='bg-blue-200 h-60 w-60 rounded-2xl'></div> */}
            </div>
        </div>
    )
}

export default ContactAdmin