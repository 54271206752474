import React, { useEffect, useState } from 'react'
import SubPaymentAdmin from './SubPaymentAdmin'
import AddLinks from '../addLink/AddLinks'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const PaymentsAdmin = ({ userId }) => {
    const LinkType = "payments"
    const [refresh, setRefresh] = useState(false)
    const [paymentsData, setPaymentsData] = useState([])

    useEffect(() => {
        getPaymentLinks(userId)
    }, [refresh])

    //toggles refresh variable to trigger useEffect 
    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const getPaymentLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setPaymentsData(Links.data.listLinks.items)
    }

    return (
        <div className='w-full flex items-center justify-center '>

            <div className='w-full md:w-1/2'>
                <div className=' md:my-16 flex flex-col items-center'>
                    <div className='w-full'>
                        <div className='text-2xl md:text-3xl w-full text-center'>Payments</div>
                    </div>
                    <div className='flex flex-wrap justify-center w-full mt-2 md:mt-4'>
                        {paymentsData.map((payment) => {
                            return (
                                <SubPaymentAdmin paymentsData={payment} key={payment.linkId} handleRefresh={handleRefresh} />
                            )
                        })}
                    </div>
                    <AddLinks type={LinkType} userId={userId} handleRefresh={handleRefresh} />
                </div>
            </div>
        </div>
    )
}

export default PaymentsAdmin
