import React, { useEffect, useState } from 'react'
import SubOrder from './SubOrder'

import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const Order = ({ userId }) => {
    const [orderData, setOrderData] = useState(null)
    const LinkType = "orders"

    useEffect(() => {
        getOrderLinks(userId)
    }, [userId])

    const getOrderLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setOrderData(Links.data.listLinks.items)
        if (Links.data.listLinks.items.length === 0) {
            setOrderData(null)
        }
    }

    return (
        <div className='w-full flex items-center justify-center'>
            {orderData !== null &&
                <div className='flex flex-col items-center my-2 md:my-6 w-full md:w-1/2'>
                    <div className='md:my-16 flex flex-col items-center  w-full '>
                        <div className='md:w-full'>
                            <div className='text-2xl md:text-3xl text-center w-full'>Order Online</div>
                            {/* <div className='text-center w-full'>Feel free to chat with us!</div> */}
                        </div>
                        <div className='flex flex-wrap justify-center  w-full mt-4'>
                            {orderData.map((order) => {
                                return (
                                    <SubOrder orderData={order} key={order.link_name} />
                                )
                            })}
                        </div>
                    </div>
                    {/* <div className='bg-blue-200 h-60 w-60 rounded-2xl'></div> */}
                </div>
            }
        </div>
    )
}

export default Order
