import React, { useEffect, useState } from 'react'
import SubSocialAdmin from './SubSocialAdmin'
import AddLinks from '../addLink/AddLinks'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { listLinks as ListLinks } from '../../../graphql/queries'

const SocialAdmin = ({ userId }) => {
    const LinkType = "socials"
    const [refresh, setRefresh] = useState(false)
    const [socialData, setSocialData] = useState([])

    useEffect(() => {
        getPaymentLinks(userId)
    }, [refresh])

    //toggles refresh variable to trigger useEffect 
    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const getPaymentLinks = async (userId) => {
        const Links = await API.graphql(graphqlOperation(ListLinks, { filter: { userLinksId: { eq: userId }, link_type: { eq: LinkType } } }))
        setSocialData(Links.data.listLinks.items)
    }

    return (
        <div className='w-full flex items-center justify-center'>
            <div className='flex flex-col  items-center my-2 md:my-6 w-full md:w-1/2'>
                <div className='md:my-16 flex flex-col items-center w-full'>
                    <div className='md:w-full '>
                        <div className='text-2xl md:text-3xl text-center w-full'>Social</div>
                        <div className=' text-center w-full'>Love us! Rate us</div>
                    </div>
                    <div className='flex flex-wrap justify-center  w-full mt-4'>
                        {socialData.map((social) => {
                            return (
                                <SubSocialAdmin socialData={social} key={social.linkId}
                                    handleRefresh={handleRefresh} />
                            )
                        })}
                    </div>
                </div>
                <AddLinks type={LinkType} userId={userId} handleRefresh={handleRefresh} />
            </div>
        </div>
    )
}

export default SocialAdmin