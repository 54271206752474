/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      id
      userId
      name
      phone_number
      username
      header {
        nextToken
      }
      review {
        nextToken
      }
      owner {
        nextToken
      }
      Links {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userId: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        name
        phone_number
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHeader = /* GraphQL */ `
  query GetHeader($id: ID!) {
    getHeader(id: $id) {
      id
      HeaderId
      header_image
      profile_image
      name
      createdAt
      updatedAt
      userHeaderId
    }
  }
`;
export const listHeaders = /* GraphQL */ `
  query ListHeaders(
    $id: ID
    $filter: ModelHeaderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHeaders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        HeaderId
        header_image
        profile_image
        name
        createdAt
        updatedAt
        userHeaderId
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($reviewId: ID!) {
    getReview(reviewId: $reviewId) {
      reviewId
      review_from
      review_star
      total_review
      review_link
      review_platform_icon
      createdAt
      updatedAt
      userReviewId
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $reviewId: ID
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReviews(
      reviewId: $reviewId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        reviewId
        review_from
        review_star
        total_review
        review_link
        review_platform_icon
        createdAt
        updatedAt
        userReviewId
      }
      nextToken
    }
  }
`;
export const getOwner = /* GraphQL */ `
  query GetOwner($ownerId: ID!) {
    getOwner(ownerId: $ownerId) {
      ownerId
      owner_name
      owner_image
      createdAt
      updatedAt
      userOwnerId
    }
  }
`;
export const listOwners = /* GraphQL */ `
  query ListOwners(
    $ownerId: ID
    $filter: ModelOwnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOwners(
      ownerId: $ownerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerId
        owner_name
        owner_image
        createdAt
        updatedAt
        userOwnerId
      }
      nextToken
    }
  }
`;
export const getLinks = /* GraphQL */ `
  query GetLinks($linkId: ID!) {
    getLinks(linkId: $linkId) {
      linkId
      link_type
      link_name
      link_icon
      link
      createdAt
      updatedAt
      userLinksId
    }
  }
`;
export const listLinks = /* GraphQL */ `
  query ListLinks(
    $linkId: ID
    $filter: ModelLinksFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLinks(
      linkId: $linkId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        linkId
        link_type
        link_name
        link_icon
        link
        createdAt
        updatedAt
        userLinksId
      }
      nextToken
    }
  }
`;
