import React from 'react'
import { MdClose } from 'react-icons/md'

//amplify
import { API, graphqlOperation } from 'aws-amplify'
import { deleteLinks as DeleteLinks } from '../../../graphql/mutations'


const SubPaymentAdmin = ({ paymentsData, handleRefresh }) => {


    //deletes the link then refreshes the page
    const handleDelete = async () => {
        const deletedLink = await API.graphql(graphqlOperation(DeleteLinks, { input: { linkId: paymentsData.linkId } }))
        handleRefresh()
    }


    return (
        <div className='mx-4 my-4 w-28 flex flex-col items-center relative'>
            <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'>
                {/* <img className='w-12' src={paymentsData.link_icon} alt='icon' /> */}
                <div className='text-white text-4xl'>{paymentsData.link_name[0].toUpperCase()}</div>
            </div>
            <div className='mt-2 text-center'>{paymentsData.link_name}</div>
            <button className="transition duration-500 w-4 h-4 hover:h-6 hover:w-6 hover:bg-red-600 hover:-top-4 hover:right-0 rounded-sm bg-red-400 flex items-center justify-center ml-2 cursor-pointer absolute -top-3 right-2"
                type="button"
                onClick={handleDelete}
            >
                <MdClose size="15" color='white' />
            </button>
        </div>
    )
}

export default SubPaymentAdmin
