import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify'

import Footer from '../../components/business/footer/Footer'
import HeaderAdmin from '../../components/admin/HeaderAdmin'
import PaymentsAdmin from '../../components/admin/payments/PaymentsAdmin'
import { AdminProvider } from '../../context/AdminContext'
import ReviewAdmin from '../../components/admin/review/ReviewAdmin'
import ContactAdmin from '../../components/admin/contact/ContactAdmin'
import OrderAdmin from '../../components/admin/order/OrderAdmin'
import SocialAdmin from '../../components/admin/social/SocialAdmin'
import OwnerAdmin from '../../components/admin/owner/OwnerAdmin'

const Dashboard = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState('')
    const [refresh, setRefresh] = useState(false)


    useEffect(() => {
        currentUser();
    }, [])

    useEffect(() => {
        setRefresh(!refresh)
    }, [user])

    const currentUser = () => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                if ("attributes" in user === true) {
                    Auth.currentUserInfo().then((user) => {
                        setUser(user.username)
                        console.log(user.username)
                    })
                } else {
                    navigate("/admin/login");
                    console.log('error')
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <AdminProvider>
            <div className='min-h-screen'>
                {user.length > 1 &&
                    <div className='min-h-screen'>

                        <HeaderAdmin userId={user} />

                        <PaymentsAdmin userId={user} />

                        <ReviewAdmin userId={user} />
                        {/* rating changed to ReviewAdmin */}

                        <ContactAdmin userId={user} />

                        <OrderAdmin userId={user} />

                        <SocialAdmin userId={user} />

                        <OwnerAdmin userId={user} />


                        {/* <div className='w-full flex justify-center items-center'><div className='pt-1 bg-gray-300 w-1/2 md:1/6 rounded-lg my-6'></div> </div> */}

                        <Footer />
                    </div>
                }
                {/* for testing */}
                {/* {console.log(data)} */}
            </div>
        </AdminProvider>
    )
}

export default Dashboard
