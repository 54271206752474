import React, { useState, useEffect } from 'react'
import axios from 'axios'


const SubPayment = ({ paymentsData }) => {

    const [link, setLink] = useState(paymentsData.link);
    const [iconLink, setIconLink] = useState(null)

    useEffect(() => {
        linkUpdate();

    }, []);

    const getDomain = (url) => {
        let domain = (new URL(url));
        domain = domain.hostname.replace('www.', '')
        console.log(domain)
        setIconLink(`https://logo.clearbit.com/${domain}`)
    }

    const linkUpdate = () => {
        let url = link
        if (link.substr(0, 3) !== "http" && link.substr(0, 1) !== "//") {
            setLink("https://" + link)
            url = "https://" + link
        }
        getDomain(url);
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <div className='mx-4 my-4 w-28 flex flex-col items-center'>
                <div className='bg-blue-400 h-16 w-16 rounded-full text-center flex justify-center items-center transition duration-150 ease-in-out hover:scale-110'>
                    {iconLink !== null ? <img className='w-16 h-16 rounded-full ' src={iconLink} alt='icon' /> :
                        <div className='text-white text-4xl'>{paymentsData.link_name[0].toUpperCase()}</div>
                    }
                </div>
                <div className='mt-2 text-center'>{paymentsData.link_name}</div>
            </div>
        </a>
    )
}

export default SubPayment
