/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:a3968002-fe0e-4edc-b1e5-f704f0f3046e",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_CcLVCA98b",
    "aws_user_pools_web_client_id": "3kbknnfcd76fv9nr88d5et7301",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://woa2nyh3urh5zj3lgsoxbw7gpm.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fs3s3s3h7bfd3dfgent2vq4k4y",
    "aws_appsync_dangerously_connect_to_http_endpoint_for_testing": true,
    "aws_user_files_s3_bucket": "intrnetpe6452703b871d4a69be14f11178c3af1f110256-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
